import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Cta from '../components/Cta';
import CollectionGallery from '../components/CollectionGallery';
import FixedNavbar from '../components/FixedNavbar';
import Layout from '../components/Layout';
import Section from '../components/layout/Section';

export default ({ data, location }) => {
  const title = `${data.site.siteMetadata.title} | ${data.contentfulCollection.title}`;
  const firstImage = data.contentfulCollection.gallery[0];

  return (
    <Layout location={location}>
      <Helmet>
        <title>{title}</title>
        <meta name="og:title" content={title} />
        <meta name="og:image" content={firstImage.full.src} />
        <meta
          name="og:url"
          content={data.site.siteMetadata.url + location.pathname}
        />
      </Helmet>
      <FixedNavbar location={location} />
      <Section>
        <CollectionGallery collection={data.contentfulCollection} />
        <Cta link="/collections" text="See other collections" />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query getCollectionBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        url
      }
    }
    contentfulCollection(slug: { eq: $slug }) {
      title
      year
      gallery {
        title
        ...ImageGridSizes
      }
      description {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
